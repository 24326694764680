import { render, staticRenderFns } from "./goods.vue?vue&type=template&id=f36d9664&scoped=true&"
import script from "./goods.vue?vue&type=script&lang=js&"
export * from "./goods.vue?vue&type=script&lang=js&"
import style0 from "./goods.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./goods.vue?vue&type=style&index=1&id=f36d9664&lang=scss&scoped=true&"
import style2 from "./goods.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f36d9664",
  null
  
)

export default component.exports